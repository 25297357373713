<template>
  <div class="forumDetails_page">
    <div class="typeArea">
      <div class="crumb">
        <breadcrumb :refer="refer"></breadcrumb>
      </div>
      <div class="content">
        <div class="left">
          <h2>驭剑</h2>
          <ul>
            <li v-for="(item, index) in productList" :class="type == index ? 'current' : ''" :key="index"
              @click="backToList(index)">
              {{ item.text }}
            </li>
          </ul>
        </div>
        <div class="right">
          <div class="article">
            <h3>{{ data.title }}</h3>
            <div class="author">
              <img :src="data.pictureUrl" alt="" />
              <span>{{ data.author }}</span>
            </div>
            <div class="description" v-html="data.textContent"></div>
            <div class="date">发布于{{ data.publishDateString }}</div>
            <div class="kind">
              <div v-for="(industryItem, industryIndex) in data.industryName" :key="industryIndex" class="tag-item">
                {{ industryItem }}
              </div>
              <div v-for="(productItem, productIndex) in data.productName" :key="productIndex + '1'" class="tag-item">
                {{ productItem }}
              </div>
            </div>
            <div v-if="$route.query.state != '1' && data.state == 1" class="like">
              <div>
                <div>
                  <svg-icon icon-class="award"></svg-icon>
                </div>
                <div>打赏</div>
                <div class="like_point">
                  <a @click="award(5)">5金币</a>
                  <a @click="award(10)">10金币</a>
                  <a @click="award(20)">20金币</a>
                </div>
              </div>
            </div>
          </div>
          <div v-if="$route.query.state != '1' && data.state == 1" class="discuss" ref="discuss">
            <div class="discuss_num">
              <a @click="like(data.isLike)" :class="[data.isLike ? 'active' : '']" title="点赞">
                <svg-icon icon-class="sword"></svg-icon>
                <svg-icon icon-class="sword_a"></svg-icon>
                <span>{{ data.likeCount }}</span>
              </a>
              <a @click="collect(data.isCollect)" :class="[data.isCollect ? 'active' : '']" title="收藏">
                <svg-icon icon-class="shield"></svg-icon>
                <svg-icon icon-class="shield_a"></svg-icon>
                <span>{{ data.collectCount }}</span>
              </a>
              <a class="active" :class="[data.isComment ? 'active' : '']" title="评论">
                <svg-icon icon-class="discuss"></svg-icon>
                <svg-icon icon-class="discuss_a"></svg-icon>
                <span>{{ data.comment }}</span>
              </a>
              <a title="转发">
                <svg-icon icon-class="share"></svg-icon>
                <span>{{ data.shareNum }}</span>
                <ul>
                  <li>
                    <a>
                      <img src="../../assets/PC/forum/icon_copy.png" alt="" />
                      <span v-clipboard:copy="link" v-clipboard:success="onCopy">复制链接</span>
                    </a>
                  </li>
                  <li>
                    <a>
                      <img src="../../assets/PC/forum/icon_sina.png" alt="" />
                      <span @click="toSina()">新浪微博</span>
                    </a>
                  </li>
                  <li>
                    <a>
                      <img src="../../assets/PC/forum/icon_wechat.png" alt="" />
                      <span>微信扫一扫</span>
                    </a>
                  </li>
                  <li class="qrCode" id="qrCode"></li>
                  <li>
                    <i class="el-icon-caret-top"></i>
                  </li>
                </ul>
              </a>
              <a>
                <span>···</span>
                <span @click="report(0, data.id)">举报</span>
              </a>
            </div>
            <div class="discuss_list">
              <div class="discuss_item" v-for="(item, index) in discussList" :key="index">
                <div class="author">
                  {{ item.userName }}
                  <div>
                    <img :src="isPc ? item.leaval.web_bck : item.leaval.app_bck" />
                    <span>{{ item.leaval.levelName }}</span>
                  </div>
                </div>
                <div class="description">
                  {{ item.forumManagementComments }}
                </div>
                <div class="num">
                  <a @click="likeReply(item.id, item.isLike, index, -1)" :class="[item.isLike ? 'active' : '']">
                    <svg-icon icon-class="sword"></svg-icon>
                    <svg-icon icon-class="sword_a"></svg-icon>
                    <span>{{ item.commentLikeCount }}</span>
                  </a>
                  <a @click="toReply(item.id, item.userName)">
                    <svg-icon icon-class="reply"></svg-icon>
                    <span>回复</span>
                  </a>
                  <a>
                    <span>···</span>
                    <span @click="report(1, item.id)">举报</span>
                  </a>
                </div>

                <div class="reply_list">
                  <div class="reply_item" :class="[replyItem.threeMore ? 'hide' : '']"
                    v-for="(replyItem, replayIndex) in item.respForumCommentValue" :key="replayIndex">
                    <div>
                      <span>{{ replyItem.userName }}</span>
                      <div>
                        <img :src="isPc ? replyItem.leaval.web_bck : replyItem.leaval.app_bck" />
                        <span>{{ replyItem.leaval.levelName }}</span>
                      </div>
                      <span>回复</span>
                      <span>{{ replyItem.firstUserName }}</span>
                      <div>
                        <img
                          :src="isPc ? (replyItem.firstLeaval ? replyItem.firstLeaval.web_bck : '') : (replyItem.firstLeaval ? replyItem.firstLeaval.app_bck : '')" />
                        <span>{{ replyItem.firstLeaval ? replyItem.firstLeaval.levelName : '' }}</span>
                      </div>
                    </div>
                    <div>{{ replyItem.forumManagementComments }}</div>
                    <div class="num">
                      <a @click="likeReply(replyItem.id, replyItem.isLike, index, replayIndex)"
                        :class="[replyItem.isLike ? 'active' : '']">
                        <svg-icon icon-class="sword"></svg-icon>
                        <svg-icon icon-class="sword_a"></svg-icon>
                        <span>{{ replyItem.commentLikeCount }}</span>
                      </a>
                      <a @click="toReply(replyItem.id, replyItem.userName)">
                        <svg-icon icon-class="reply"></svg-icon>
                        <span>回复</span>
                      </a>
                      <a>
                        <span>···</span>
                        <span @click="report(1, replyItem.id)">举报</span>
                      </a>
                    </div>
                  </div>
                  <a class="loadMore" v-if="item.respForumCommentValue && item.respForumCommentValue.length > 3"
                    @click="loadMore(index, item.operate)">{{ item.loadText }}</a>
                </div>
              </div>
              <div class="noData flex0" v-if="this.discussList.length == 0">
                暂无相关回复
              </div>

              <div class="publish_area">
                <el-input v-model="publish_text" ref="publish_input" :placeholder="placeholderTitle" maxlength="100"
                  @blur="inputBlur"></el-input>
                <a v-if="Isissue" @click="publishSubmit">评论</a>
                <a v-else @click="publishSubmit">回复</a>
              </div>

              <div class="discuss_pagination" v-if="recordCount > pageSize">
                <el-pagination background layout="pager, next" next-text="下一页" :page-size="pageSize"
                  :pager-count="pageCount" :total="recordCount" @current-change="pageClick"
                  :current-page="listParams.page" @next-click="pageClick">
                </el-pagination>
              </div>
            </div>
            <a class="onBottomLoad_btn" v-if="!noComment" @click="getComment(false)">点击加载</a>
          </div>
        </div>
      </div>
    </div>
    <div class="report_area" v-if="isReport">
      <i class="el-icon-circle-close" @click="isReport = false"></i>
      <p>举报说明</p>
      <el-input type="textarea" resize="none" placeholder="举报详情说明（必填）" v-model="report_desc"></el-input>
      <div class="btn_area">
        <a @click="isReport = false">取消</a>
        <a @click="reportSubmit">举报</a>
      </div>
      <div class="report_privacy">
        请在举报时简述理由，感谢你与我们共同维护社区氛围。
        <router-link to="/AgreeMent" target="_blank" class="violet">点击了解更多社区规范。</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { common_msg_update, debounce, GetWxShares } from "@/utils/common.js";
import Headers from "@/components/header.vue";
import Breadcrumb from "@/components/Breadcrumb/index.vue";
import * as forum from "../../api/forum.js";
import QRCode from "qrcodejs2";
import { dataState } from "@/api/all.js";

export default {
  name: "forumDetails",
  components: {
    Headers,
    Breadcrumb,
  },
  created() {
    if (this.screenWidth < 768) {
      this.isPc = false;
    } else {
      this.isPc = true;
    }
    this.detailsParams.forumId = this.$route.query.id;
    this.listParams.forumId = this.$route.query.id;
    this.initial();

  },
  data() {
    return {
      replyArr: [],
      reportType: "",
      reportId: "",
      link: "",
      noComment: true,
      publish_text: "",
      report_desc: "",
      isReport: false,
      type: "",
      pageSize: 5,
      pageCount: 5,
      recordCount: 0,
      current: 0,
      isPc: true,
      isInitial: true,
      screenWidth: document.body.clientWidth,
      pageTitle: '',
      refer: [
        { name: "首页", href: "/" },
        { name: "驭剑", href: "forum" },
      ],
      detailsParams: {
        forumId: "",
      },
      likeParams: {},
      addCommentParams: {
        forumManagementId: "",
        commentPid: "",
        forumManagementComments: "",
      },
      productList: [],
      listParams: {
        page: 1,
        limit: 5,
        forumId: "",
      },
      data: {
        theme: "",
        headImg: "",
        author: "",
        description: "",
        kv: "",
        date: "",
        kind: [],
        isLike: false,
        swordNum: 0,
        shieldNum: 0,
        discussNum: 0,
      },
      discussList: [],
      Isissue: true,                 // !发布、回复文字切换
      placeholderTitle: "请发表评论，字数50以上"  // !评论框
    };
  },
  mounted() {


    if (this.$route.query.code) {
      this.shareNums(this.$route.query.id);
    }

    // 数据统计
    const data = {
      visitType: 1,
      gid: sessionStorage.getItem("gid") ? sessionStorage.getItem("gid") : "",
      url: window.location.href,
      pageUrl: "ForumDetails",
      relationId: this.$route.query.id,
    };
    dataState(data).then((res) => {
      sessionStorage.setItem("gid", res.data);
    });
  },
  methods: {
    initial() {
      this.getProduct();
      this.getComment();
      this.link = location.href;
      this.isInitial = false;
    },
    // !微博分享
    toSina() {
      this.shareNums(this.$route.query.id);
      var title = this.data.title;
      var url = window.location.href;
      url = encodeURIComponent(url);
      var a = screen.width / 2 - 300,
        d = screen.height / 2 - 175;
      window.open(
        "http://v.t.sina.com.cn/share/share.php?title=" + title + "&url=" + url,
        "Ignite",
        "status=0,toolbar=0,location=0,menubar=0,directories=0,resizable=1,scrollbars=0,width=800,height=530,top=" +
        d +
        ",left=" +
        a
      );
    },
    // !草料二维码
    qrcode() {
      let that = this;
      let qrcode = new QRCode("qrCode", {
        width: 104,
        height: 104,
        text: this.link + "&code=" + Math.random(),
      });
    },
    // !复制成功
    onCopy() {
      this.$toast({ message: "复制成功!", duration: 1000 });
    },
    likeReply(id, isLike, index, replyIndex) {
      debounce(() => {
        if (this.$store.state.token) {
          if (!isLike) {
            forum
              .likeComment({
                forumCommentId: id,
              })
              .then((res) => {
                this.$toast({
                  message: res.message,
                  duration: 1000,
                });
                if (res.status == 1) {
                  if (replyIndex != -1) {
                    this.discussList[index].respForumCommentValue[
                      replyIndex
                    ].isLike = true;
                    this.discussList[index].respForumCommentValue[replyIndex]
                      .commentLikeCount++;
                  } else {
                    this.discussList[index].isLike = true;
                    this.discussList[index].commentLikeCount++;
                  }
                }
              });
          } else {
            forum
              .likeCancelComment({
                forumCommentId: id,
              })
              .then((res) => {
                this.$toast({
                  message: res.message,
                  duration: 1000,
                });
                if (res.status == 1) {
                  if (replyIndex != -1) {
                    this.discussList[index].respForumCommentValue[
                      replyIndex
                    ].isLike = false;
                    this.discussList[index].respForumCommentValue[replyIndex]
                      .commentLikeCount--;
                  } else {
                    this.discussList[index].isLike = false;
                    this.discussList[index].commentLikeCount--;
                  }
                }
              });
          }
        } else {
          this.$toast({
            message: "请登录后操作!",
            duration: 1000,
          });
        }
      }, 1000);
    },
    // !点赞
    like(isLike) {
      debounce(() => {
        if (this.$store.state.token) {
          if (!isLike) {
            forum
              .likeArticle({
                forumManagementId: this.data.id,
              })
              .then((res) => {
                if (res.status == 1) {
                  if (res.message > 0) {
                    this.$toast("点赞成功！+" + res.message + " 积分");
                  } else {
                    this.$toast("点赞成功！");
                  }
                  this.getDetails();
                } else {
                  this.$toast(res.message);
                }
              });
          } else {
            forum
              .likeCancelArticle({
                forumManagementId: this.data.id,
              })
              .then((res) => {
                if (res.status == 1) {
                  this.$toast({
                    message: res.message,
                    duration: 1000,
                  });
                  this.getDetails();
                }
              });
          }
        } else {
          this.$toast({
            message: "请登录后操作!",
            duration: 1000,
          });
        }
      }, 1000);
    },
    // !收藏
    collect(isCollect) {
      debounce(() => {
        if (this.$store.state.token) {
          if (!isCollect) {
            forum
              .collectArticle({
                forumManagementId: this.data.id,
              })
              .then((res) => {
                if (res.status == 1) {
                  if (res.message > 0) {
                    this.$toast("收藏成功！+" + res.message + " 积分");
                  } else {
                    this.$toast("收藏成功！");
                  }
                  this.getDetails();
                } else {
                  this.$toast(res.message);
                }
              });
          } else {
            forum
              .collectCancelArticle({
                forumManagementId: this.data.id,
              })
              .then((res) => {
                if (res.status == 1) {
                  this.$toast({
                    message: res.message,
                    duration: 1000,
                  });
                  this.getDetails();
                }
              });
          }
        } else {
          this.$toast({
            message: "请登录后操作!",
            duration: 1000,
          });
        }
      }, 1000);
    },
    // !举报
    reportSubmit() {
      debounce(() => {
        if (!this.report_desc) {
          this.$toast({
            message: "请填写举报内容",
            duration: 1000,
          });
          return;
        }
        if (this.reportType == 0) {
          forum.report({
            forumManagementId: this.data.id,
            reportContent: this.report_desc,
          }).then((res) => {
            this.$toast({
              message: res.message,
              duration: 1000,
            });
            if (res.status == 1) {
              this.report_desc = "";
              this.isReport = false;
            }
          }).then(() => {
            common_msg_update();
          });
        } else {
          forum.reportComment({
            forumCommentId: this.reportId,
            reportContent: this.report_desc,
          })
            .then((res) => {
              this.$toast({
                message: res.message,
                duration: 1000,
              });
              if (res.status == 1) {
                this.report_desc = "";
                this.isReport = false;
              }
            }).then(() => {
              common_msg_update();
            });
        }
      }, 1000);
    },
    // !点击回复获取焦点
    toReply(id, name) {
      if (this.$store.state.token) {
        this.placeholderTitle = "回复 @" + name + ':'
        this.publish_text = "";
        this.addCommentParams.commentPid = id;
        this.$refs.publish_input.focus();
        this.Isissue = false;
      } else {
        this.$toast({
          message: "请登录后操作!",
          duration: 1000,
        });
      }
    },
    // !发布评论
    publishSubmit() {
      debounce(() => {
        if (this.$store.state.token) {
          if (!this.publish_text) {
            this.$toast("请填写评论内容！");
            return;
          } else {
            if (this.Isissue) {
              if (this.publish_text.length < 50) {
                this.$toast("评论内容必须大于等于50个字符！");
                return false;
              }
            } else {
              if (this.publish_text.length < 3) {
                this.$toast("评论内容必须大于等于2个字符！");
                return false;
              }
            }
          }
          if (this.Isissue) {
            this.addCommentParams.commentPid = ''
          }

          this.addCommentParams.forumManagementId = this.$route.query.id;
          this.addCommentParams.forumManagementComments = this.publish_text;
          this.addCommentParams.commentPid = this.addCommentParams.commentPid ? this.addCommentParams.commentPid : "00000000-0000-0000-0000-000000000000";
          forum.addComment(this.addCommentParams).then((res) => {
            if (res.status == 1) {
              if (res.message > 0) {
                this.$toast("评论成功！+" + res.message + " 积分");
              } else {
                this.$toast("评论成功！");
              }
              this.listParams.page = 1;
              this.getComment(true);
              this.publish_text = "";
            } else {
              this.$toast(res.message);
            }
          });
        } else {
          this.$toast({
            message: "请登录后操作!",
            duration: 1000,
          });
        }
      }, 1000);
    },
    // !打赏金币
    award(point) {
      if (this.$store.state.token) {
        this.$confirm("确认打赏么？", "提示", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
        })
          .then(() => {
            forum
              .award({
                forumId: this.$route.query.id,
                giveMoney: point,
              })
              .then((res) => {
                if (res.status == 1) {
                  this.$toast.success({
                    message: res.message,
                    duration: 1000,
                  });
                } else {
                  this.$toast(res.message);
                }
              });
          })
          .catch((action) => { });
      } else {
        this.$toast({
          message: "请登录后操作!",
          duration: 1000,
        });
      }
    },
    report(type, id) {
      if (this.$store.state.token) {
        this.report_desc = "";
        this.reportId = id;
        this.reportType = type;
        this.isReport = true;
      } else {
        this.$toast({
          message: "请登录后操作!",
          duration: 1000,
        });
      }
    },
    backToList(index) {
      this.$router.push("/Home/forum?type=" + this.productList[index].value);
    },
    getProduct() {
      forum.getProduct().then((res) => {
        if (res.status == 1) {
          var data = []
          res.data.products.map(item => {
            if (item.extend2 == '' || item.extend2 == '驭剑') {
              data.push(item)
            }
          })
          this.productList = data;
          this.getDetails();
        }
      });
    },
    getDetails() {
      forum.getDetails(this.detailsParams).then((res) => {
        if (res.status == 1) {
          if (res.data[0].industry != "") {
            res.data[0].industry = JSON.parse(res.data[0].industry);
            res.data[0].industryName = JSON.parse(res.data[0].industryName);
          } else {
            res.data[0].industry = [];
            res.data[0].industryName = [];
          }
          if (res.data[0].product != "") {
            res.data[0].product = JSON.parse(res.data[0].product);
            res.data[0].productName = JSON.parse(res.data[0].productName);
            this.productList.map((item, index) => {
              if (item.value == res.data[0].product[0]) {
                this.type = index;
              }
            });
          } else {
            res.data[0].product = [];
            res.data[0].productName = [];
          }
          if (this.refer[this.refer.length - 1].name == "驭剑") {
            this.refer.push({
              name: res.data[0].title,
            });
          }

          const share = {
            title: res.data[0].title,
            desc: ''
          }
          GetWxShares(share);

          this.data = res.data[0];
          this.$nextTick(function () {
            const qrcode = document.getElementById("qrCode");
            if (qrcode) {
              this.qrcode();
            }
          });
        } else {
          this.$toast(res.message);
          setTimeout(() => {
            this.$router.push('/Home/Forum');
          }, 500);
        }
      });
    },
    // !评论数据
    getComment(isPublish) {
      if (!this.isPc && !this.isInitial && !isPublish) {
        this.listParams.page++;
      }
      forum.getComment(this.listParams).then((res) => {
        if (res.status == 1 && res.data != null) {
          res.data.map((item) => {
            item.loadText = "查看更多";
            item.operate = 0;
            item.reportBtnShow = false;
            this.replyArr = [];
            item.respForumCommentValue = this.circul(
              item.respForumCommentValue
            );
          });
          this.$nextTick(() => {
            res.data.map((item) => {
              if (item.respForumCommentValue) {
                item.respForumCommentValue.map((replyItem, replyIndex) => {
                  if (replyIndex > 2) {
                    replyItem.threeMore = true;
                  }
                });
              }
            });
          });
          if (!this.isPc) {
            if (isPublish) {
              this.discussList = [];
            }
            this.discussList.push(...res.data);
            if (this.discussList.length == res.recordCount) {
              this.noComment = true;
            } else {
              this.noComment = false;
            }
          } else {
            this.discussList = res.data;
          }

          this.recordCount = res.recordCount;
          this.Isissue = true;
          this.placeholderTitle = '请发表评论，字数50以上'
        }
      });
    },
    circul(arr) {
      if (arr.length) {
        arr.map((item) => {
          this.replyArr.push(item);
          this.circul(item.respForumCommentValue);
        });
      } else {
        return;
      }
      return this.replyArr;
    },
    loadMore(index, operate) {
      if (operate == 0) {
        this.discussList[index].loadText = "收起";
        this.discussList[index].operate = 1;
        this.discussList[index].respForumCommentValue.map((item) => {
          item.threeMore = false;
        });
      } else {
        this.discussList[index].loadText = "查看更多";
        this.discussList[index].operate = 0;
        this.discussList[index].respForumCommentValue.map((item, index) => {
          if (index > 2) {
            item.threeMore = true;
          }
        });
      }
    },
    pageClick(page) {
      this.listParams.page = page;
      this.getComment();
    },
    // !回复框失去焦点
    inputBlur() {
      this.publish_text = this.publish_text.trim();
      if (this.publish_text == "") {
        this.Isissue = true;
        this.placeholderTitle = '请发表评论，字数50以上'
      }
    },
    // !记录分享次数
    shareNums(id) {
      const Id = { forumManagementId: id };
      forum.shareNum(Id).then((res) => { });
    },
  },
  watch: {
    screenWidth(val) {
      this.screenWidth = val;
      if (this.screenWidth < 768) {
        this.isPc = false;
      } else {
        this.isPc = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$mobil_base: 750;

@function vw($true) {
  @return ($true / $mobil_base) * 100vw;
}

.forumDetails_page {
  .report_privacy .violet {
    font-size: 14px;
  }

  .discuss-Lists {
    margin-left: 25px;
  }

  .discuss-item {
    margin-bottom: 25px;
  }

  .discuss-item-user {
    min-height: 30px;
  }

  .discuss-item-user-name {
    font-size: 16px;
    margin-right: 5px;
  }

  .discussItem-item-user-bg {
    position: relative;
  }

  .discussItem-item-user-bg img {
    height: 24px;
    margin-top: 3px;
  }

  .discussItem-item-user-bg span {
    font-size: 12px;
    color: #fff;
    position: absolute;
    right: 6px;
    top: 5px;
  }

  .discuss-item-commont {
    margin: 7.5px 0 15px 0;
    font-size: 16px;
  }

  .iLike {
    position: relative;
  }

  .iLike svg {
    position: absolute;
    left: 0;
    font-size: 17px;
  }

  .iLike svg:nth-of-type(2) {
    opacity: 0;
  }

  .iLike span {
    margin-left: 25px;
    font-size: 16px;
  }

  .SvgActive.iLike svg:nth-of-type(2) {
    opacity: 1;
    transition: all 0.3s;
  }
}

.forumDetails_page {
  .Header {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  }

  .report_area {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 830px;
    padding: 80px 50px 90px;
    background: #fff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
    z-index: 8;

    i {
      position: absolute;
      top: 32px;
      right: 32px;
      font-size: 24px;
      cursor: pointer;
    }

    p {
      font-size: 28px;
      color: #323332;
      margin: 0 0 42px;
      text-align: center;
    }

    /deep/ .el-textarea {
      padding: 0;
      margin: 0 0 40px;

      textarea {
        height: 227px !important;
        border: 0;
        background: #f6f8fa;
        padding: 20px;
        font-size: 16px;
      }
    }

    .btn_area {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 0 80px;

      a {
        display: block;
        width: 236px;
        height: 36px;
        font-size: 16px;
        line-height: 36px;
        text-align: center;
        cursor: pointer;

        &:first-of-type {
          color: #6b18a8;
          bacground: #fff;
          border: 1px solid #6b18a8;
          margin-right: 80px;
        }

        &:nth-of-type(2) {
          color: #fff !important;
          background: #6b18a8;
          border: 0;
        }
      }
    }

    .report_privacy {
      font-size: 16px;
      color: #9eabba;
      line-height: 26px;
    }
  }

  .typeArea {
    position: relative;

    .crumb {
      margin: 30px 0;
    }

    .content {
      display: flex;
      justify-content: space-between;

      .left {
        width: 150px;

        h2 {
          font-size: 24px;
          color: #363d42;
          line-hieght: 40px;
          margin: 0 0 24px 0;
        }

        ul {
          background: #f6f8fa;

          li {
            font-size: 16px;
            height: 60px;
            line-height: 60px;
            color: #9eabba;
            text-align: center;
            cursor: pointer;

            &:hover {
              color: #6b18a8;
              font-weight: 700;
            }

            &.current {
              color: #6b18a8;
              font-weight: 700;
            }
          }
        }
      }

      .right {
        width: 1000px;

        .article {
          h3 {
            font-size: 20px;
            color: #434343;
            margin-bottom: 14px;
          }

          .author {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            img {
              width: 40px;
              margin-right: 10px;
            }

            span {
              font-size: 16px;
              color: #363d42;
            }
          }

          .description {
            margin-bottom: 40px;

            p {
              font-size: 16px;
              color: #434343;
              line-height: 26px;
              margin-bottom: 40px;
            }

            img {
              width: 100%;
            }
          }

          .date {
            font-size: 16px;
            color: #738294;
            margin-bottom: 20px;
          }

          .kind {
            display: flex;
            margin-bottom: 20px;

            a {
              display: block;
              padding: 0 8px;
              min-width: 34px;
              border: 1px solid #e6e6e6;
              height: 26px;
              line-height: 26px;
              font-size: 16px;
              color: #6b18a8;
              text-align: center;
              font-weight: 700;
              margin: 0 12px 0 0;
            }
          }

          .like {
            margin-bottom: 20px;

            &>div {
              width: 130px;
              height: 54px;
              border: 1px solid #6b18a8;
              border-radius: 27px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin: 0 auto;
              cursor: pointer;
              position: relative;

              div:first-of-type {
                width: 54px;
                height: 54px;
                background: #6b18a8;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                  width: 26px;
                  height: 27px;
                }
              }

              div:nth-of-type(2) {
                width: 76px;
                height: 54px;
                line-height: 54px;
                font-size: 16px;
                color: #434343;
                text-align: center;
              }

              div:nth-of-type(3) {
                display: none;
                justify-content: center;
                align-items: center;
                position: absolute;
                width: 419px;
                height: 124px;
                background: url(../../assets/PC/forum/point_bac.png) center no-repeat;
                background-size: 100%;
                bottom: -124px;
                left: -50px;
                z-index: 6;

                a {
                  width: 108px;
                  height: 40px;
                  line-height: 40px;
                  text-align: center;
                  border: 1px solid #6b18a8;
                  border-radius: 4px;
                  margin-left: 12px;

                  &:first-of-type {
                    margin-left: 0;
                  }

                  &:hover {
                    color: #fff !important;
                    font-weight: 700;
                    background: #6b18a8;
                  }

                  &.active {
                    color: #fff !important;
                    font-weight: 700;
                    background: #6b18a8;
                  }
                }
              }

              &:hover {
                div:nth-of-type(3) {
                  display: flex;
                }
              }
            }
          }
        }

        .discuss {
          margin-bottom: 160px;

          .discuss_num {
            display: flex;

            &>a {
              padding: 0 20px;
              height: 32px;
              line-height: 32px;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              position: relative;

              span {
                font-size: 16px;
                color: #363d42;
                margin-left: 8px;
              }

              svg {
                transition: all 0.5s;
              }

              svg:first-of-type {
                opacity: 1;
              }

              svg:nth-of-type(2) {
                opacity: 0;
                position: absolute;
                left: 20px;
              }

              &:first-of-type {
                svg {
                  width: 17px;
                  height: 17px;
                }
              }

              &:nth-of-type(2) {
                svg {
                  width: 17px;
                  height: 21px;
                }
              }

              &:nth-of-type(3) {
                svg {
                  width: 18px;
                  height: 18px;
                }

                &.active {
                  background: #f6f8fa;
                }
              }

              &:nth-of-type(4) {
                margin-right: 10px;
                position: relative;

                svg {
                  width: 20px;
                  height: 20px;
                }

                &:hover ul {
                  display: block;
                }

                ul {
                  display: none;
                  width: 156px;
                  height: 295px;
                  background: url(../../assets/PC/forum/share_bac.png) center no-repeat;
                  background-size: 100%;
                  padding: 20px 0 0 0;
                  position: absolute;
                  top: 30px;
                  left: -48px;
                  z-index: 5;

                  li {
                    width: 100%;
                    line-height: 36px;
                    margin-top: 8px;
                    display: flex;
                    padding-left: 22px;
                    align-items: center;

                    span {
                      font-size: 16px;
                      color: #6c7179;
                      margin-left: 6px;
                    }

                    &:first-of-type {
                      img {
                        width: 20px;
                      }
                    }

                    &:nth-of-type(2) {
                      img {
                        width: 22px;
                      }
                    }

                    &:nth-of-type(3) {
                      img {
                        width: 22px;
                      }
                    }

                    &:nth-of-type(4) {
                      justify-content: center;
                      padding: 0;

                      img {
                        width: 104px;
                      }
                    }

                    &:nth-of-type(5) {
                      display: none;
                    }
                  }
                }
              }

              &:nth-of-type(5) {
                position: relative;

                span:first-of-type {
                  font-weight: bolder;
                }

                &:hover span:nth-of-type(2) {
                  display: block;
                }

                span:nth-of-type(2) {
                  display: none;
                  position: absolute;
                  top: 24px;
                  right: -32px;
                  width: 123px;
                  height: 41px;
                  background: url(../../assets/PC/forum/report_bac.png) center no-repeat;
                  font-size: 16px;
                  color: #fff !important;
                  line-height: 50px;
                  text-align: center;
                  z-index: 5;
                }
              }

              &.active {
                border: 0;
                background: none;

                svg:first-of-type {
                  opacity: 0;
                }

                svg:nth-of-type(2) {
                  opacity: 1;
                }
              }
            }
          }

          .discuss_list {
            padding: 0 30px 30px 30px;
            background: #f6f8fa;

            .discuss_item {
              padding: 20px 0;
              margin-bottom: 30px;
              border-bottom: 1px solid #e6e6e6;

              .author {
                font-size: 16px;
                color: #363d42;
                font-weight: 700;
                margin-bottom: 5px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;

                div {
                  position: relative;
                  top: 3px;

                  span {
                    position: absolute;
                    left: 42px;
                    top: 5px;
                    color: #fff;
                  }
                }
              }

              .description {
                font-size: 16px;
                line-height: 26px;
                color: #434343;
                margin-bottom: 20px;
              }

              .num {
                display: flex;
                margin-bottom: 40px;

                &>a {
                  display: flex;
                  align-items: center;
                  margin-right: 40px;
                  cursor: pointer;
                  position: relative;

                  span {
                    font-size: 16px;
                    color: #363d42;
                    margin-left: 8px;
                  }

                  svg {
                    transition: all 0.5s;
                  }

                  svg:first-of-type {
                    opacity: 1;
                  }

                  svg:nth-of-type(2) {
                    opacity: 0;
                    position: absolute;
                    left: 0;
                  }

                  &:first-of-type {
                    svg {
                      width: 17px;
                      height: 17px;
                    }
                  }

                  &:nth-of-type(2) {
                    svg {
                      width: 18px;
                      height: 16px;
                    }
                  }

                  &:nth-of-type(3) {
                    position: relative;

                    span:first-of-type {
                      font-weight: bolder;
                    }

                    &:hover span:nth-of-type(2) {
                      display: block;
                    }

                    span:nth-of-type(2) {
                      display: none;
                      position: absolute;
                      top: 20px;
                      right: -52px;
                      width: 123px;
                      height: 41px;
                      background: url(../../assets/PC/forum/report_bac.png) center no-repeat;
                      font-size: 16px;
                      color: #fff !important;
                      line-height: 50px;
                      text-align: center;
                      z-index: 5;
                    }
                  }

                  &.active {
                    border: 0;
                    background: none;

                    svg:first-of-type {
                      opacity: 0;
                    }

                    svg:nth-of-type(2) {
                      opacity: 1;
                    }
                  }
                }
              }

              .reply_list {
                padding: 0 0 0 35px;

                .reply_item {
                  &>div:first-of-type {
                    font-size: 16px;
                    margin-bottom: 10px;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;

                    &>span:first-of-type {
                      color: #434343;
                    }

                    &>span:nth-of-type(2) {
                      color: #777777;
                      margin: 0 4px;
                    }

                    &>span:nth-of-type(3) {
                      color: #363d42;
                    }

                    div {
                      position: relative;
                      top: 3px;

                      span {
                        position: absolute;
                        left: 42px;
                        top: 5px;
                        color: #fff;
                      }
                    }
                  }

                  &>div:nth-of-type(2) {
                    font-size: 16px;
                    color: #434343;
                    line-height: 26px;
                    margin-bottom: 10px;
                  }

                  &>div:nth-of-type(3) {
                    display: flex;
                    margin-bottom: 40px;

                    &>a {
                      display: flex;
                      align-items: center;
                      margin-right: 40px;
                      cursor: pointer;

                      span {
                        font-size: 16px;
                        color: #363d42;
                        margin-left: 8px;
                      }

                      svg {
                        transition: all 0.5s;
                      }

                      svg:first-of-type {
                        opacity: 1;
                      }

                      svg:nth-of-type(2) {
                        opacity: 0;
                        position: absolute;
                        left: 0;
                      }

                      &:first-of-type {
                        svg {
                          width: 17px;
                          height: 17px;
                        }
                      }

                      &:nth-of-type(2) {
                        svg {
                          width: 18px;
                          height: 16px;
                        }
                      }

                      &:nth-of-type(3) {
                        position: relative;

                        span:first-of-type {
                          font-weight: bolder;
                        }

                        &:hover span:nth-of-type(2) {
                          display: block;
                        }

                        span:nth-of-type(2) {
                          display: none;
                          position: absolute;
                          top: 20px;
                          right: -52px;
                          width: 123px;
                          height: 41px;
                          background: url(../../assets/PC/forum/report_bac.png) center no-repeat;
                          font-size: 16px;
                          color: #fff !important;
                          line-height: 50px;
                          text-align: center;
                          z-index: 5;
                        }
                      }

                      &.active {
                        border: 0;
                        background: none;

                        svg:first-of-type {
                          opacity: 0;
                        }

                        svg:nth-of-type(2) {
                          opacity: 1;
                        }
                      }
                    }
                  }

                  &:nth-of-type(n + 1) {
                    margin-top: 10px;
                  }

                  &.hide {
                    display: none;
                  }
                }

                .loadMore {
                  display: block;
                  font-size: 16px;
                  text-align: center;
                  cursor: pointer;
                }
              }
            }

            .noData {
              height: 80px;
              font-size: 24px;
              color: #ccc;
              text-align: center;
              font-weight: 700;
            }

            .publish_area {
              display: flex;
              justify-content: space-between;
              align-items: center;

              /deep/ .el-input {
                height: 48px !important;
                line-height: 48px !important;

                input {
                  width: 705px;
                  height: 48px !important;
                  line-height: 48px !important;
                  font-size: 16px;
                  border: 0;
                  background: #fff;
                  border-radius: 0;
                }
              }

              a {
                width: 213px;
                height: 48px;
                font-size: 16px;
                color: #fff !important;
                line-height: 48px;
                text-align: center;
                background: #6b18a8;
                cursor: pointer;
              }
            }

            .discuss_pagination {
              margin: 55px 0 0 0;

              .el-pagination {
                display: flex;
                justify-content: flex-end;

                /deep/ .el-pager li {
                  width: 45px;
                  height: 45px;
                  font-size: 16px;
                  color: #363d42;
                  text-align: center;
                  line-height: 45px;
                }

                /deep/ .el-pager li.active {
                  color: #fff;
                  background-color: #6b18a8;
                }

                /deep/ .btn-next {
                  width: 95px;
                  height: 45px;
                  color: #fff;
                  background-color: #6b18a8;
                }
              }
            }
          }

          .onBottomLoad_btn {
            display: none;
          }
        }
      }
    }
  }
}

@media (max-width: 750px) {
  .forumDetails_page {
    .report_privacy .violet {
      font-size: vw(24);
    }

    .Header {
      box-shadow: none;
    }

    .report_area {
      width: vw(690);
      padding: vw(80) vw(50) vw(90);
      box-shadow: 0 vw(5) vw(10) rgba(0, 0, 0, 0.5);

      i {
        top: vw(42);
        right: vw(42);
        font-size: vw(45);
      }

      p {
        font-size: vw(32);
        margin: 0 0 vw(40);
      }

      /deep/ .el-textarea {
        margin: 0 0 vw(40);

        textarea {
          height: vw(227) !important;
          padding: vw(25);
          font-size: vw(24);
        }
      }

      .btn_area {
        margin: 0 0 vw(80);

        a {
          width: vw(200);
          height: vw(66);
          font-size: vw(24);
          line-height: vw(66);

          &:first-of-type {
            border: 1px solid #6b18a8;
            margin-right: vw(80);
          }
        }
      }

      .report_privacy {
        font-size: vw(20);
        color: #9eabba;
        line-height: vw(30);
      }
    }

    .typeArea {
      .crumb {
        display: none;
        margin: vw(55) 0 vw(40);
      }

      .content {
        .left {
          display: none;
        }

        .right {
          width: 100%;
          padding-top: vw(36);

          .article {
            h3 {
              font-size: vw(32);
              margin-bottom: vw(30);
            }

            .author {
              margin-bottom: vw(32);

              img {
                width: vw(100);
                margin-right: vw(18);
              }

              span {
                font-size: vw(24);
              }
            }

            .description {
              margin-bottom: vw(40);

              p {
                font-size: vw(24);
                line-height: vw(40);
                margin-bottom: vw(14);
              }
            }

            .date {
              font-size: vw(20);
              margin-bottom: vw(40);
            }

            .kind {
              margin-bottom: vw(10);
              flex-wrap: wrap;

              a {
                padding: 0 vw(22);
                min-width: vw(94);
                border: 1px solid #e6e6e6;
                height: vw(50);
                line-height: vw(50);
                font-size: vw(24);
                margin: 0 vw(18) vw(10) 0;
              }
            }

            .like {
              margin-bottom: vw(40);

              &>div {
                width: vw(190);
                height: vw(68);
                border: 1px solid #6b18a8;
                border-radius: vw(34);

                div:first-of-type {
                  width: vw(68);
                  height: vw(68);

                  svg {
                    width: vw(40);
                  }
                }

                div:nth-of-type(2) {
                  width: vw(122);
                  height: vw(68);
                  line-height: vw(68);
                  font-size: vw(28);
                }

                div:nth-of-type(3) {
                  width: vw(680);
                  height: vw(172);
                  background: url(../../assets/PC/forum/point_bac_mobile.png) center no-repeat;
                  background-size: 100%;
                  bottom: vw(-180);
                  left: vw(-250);

                  a {
                    width: vw(180);
                    height: vw(68);
                    line-height: vw(68);
                    border-radius: vw(4);
                    margin-left: vw(24);
                  }
                }
              }
            }
          }

          .discuss {
            margin-bottom: vw(160);

            .discuss_num {
              &>a {
                padding: 0 vw(40);
                height: vw(75);
                line-height: vw(75);

                span {
                  font-size: vw(24);
                  margin-left: vw(12);
                }

                svg:nth-of-type(2) {
                  left: vw(40);
                }

                &:first-of-type {
                  padding-left: 0;

                  svg {
                    width: vw(24);
                    height: vw(24);
                  }

                  svg:nth-of-type(2) {
                    left: 0;
                  }
                }

                &:nth-of-type(2) {
                  svg {
                    width: vw(28);
                    height: vw(34);
                  }
                }

                &:nth-of-type(3) {
                  svg {
                    width: vw(29);
                    height: vw(29);
                  }
                }

                &:nth-of-type(4) {
                  margin-right: vw(15);

                  svg {
                    width: vw(27);
                    height: vw(28);
                  }

                  ul {
                    width: vw(298);
                    height: vw(220);
                    background: #ffffff;
                    box-shadow: 0px 4px 9px 1px rgba(96, 96, 96, 0.4);
                    border-radius: 8px;
                    padding: 0;
                    top: vw(80);
                    left: vw(-90);

                    li {
                      line-height: vw(110);
                      margin-top: 0;
                      display: flex;
                      padding-left: 0;
                      justify-content: center;
                      align-items: center;

                      span {
                        font-size: vw(24);
                        margin-left: vw(24);
                      }

                      &:first-of-type {
                        img {
                          width: vw(40);
                        }

                        &:hover+li+li+li+li {
                          i {
                            color: #efe7f6;
                          }
                        }
                      }

                      &:nth-of-type(2) {
                        img {
                          width: vw(54);
                        }
                      }

                      &:nth-of-type(3) {
                        display: none;
                      }

                      &:nth-of-type(4) {
                        display: none;
                      }

                      &:nth-of-type(5) {
                        display: block;
                        line-height: vw(16);
                        position: absolute;
                        top: vw(-16);
                        left: 50%;
                        transform: translateX(vw(-16));

                        i {
                          font-size: vw(16);
                          color: #fff;
                        }
                      }

                      &:hover {
                        background: #efe7f6;
                      }
                    }
                  }
                }

                &:nth-of-type(5) {
                  padding: 0;

                  span:first-of-type {
                    font-weight: bolder;
                  }

                  span:nth-of-type(2) {
                    top: vw(50);
                    right: vw(-74);
                    width: vw(163);
                    height: vw(71);
                    background: url(../../assets/PC/forum/report_bac_mobile.png) center no-repeat;
                    background-size: 100%;
                    font-size: vw(24);
                    line-height: vw(87);
                  }
                }
              }
            }

            .discuss_list {
              padding: 0 10px;

              .discuss_item {
                padding: vw(40) 0;
                border-bottom: vw(1) solid #e6e6e6;

                .author {
                  font-size: vw(24);
                  margin-bottom: vw(10);

                  div {
                    top: 1px;

                    img {
                      width: vw(170);
                      height: vw(50);
                    }

                    span {
                      font-size: vw(24);
                      left: vw(54);
                      top: vw(6);
                    }
                  }
                }

                .description {
                  font-size: vw(24);
                  line-height: vw(40);
                  margin-bottom: vw(24);
                }

                .num {
                  margin-bottom: vw(40);

                  &>a {
                    margin-right: vw(50);

                    span {
                      font-size: vw(20);
                      margin-left: vw(8);
                    }

                    &:first-of-type {
                      svg {
                        width: vw(23);
                        height: vw(23);
                      }
                    }

                    &:nth-of-type(2) {
                      svg {
                        width: vw(22);
                        height: vw(20);
                      }
                    }

                    &:nth-of-type(3) {
                      position: relative;

                      span:first-of-type {
                        font-weight: bolder;
                      }

                      span:nth-of-type(2) {
                        position: absolute;
                        top: vw(30);
                        right: vw(-67);
                        width: vw(163);
                        height: vw(71);
                        background: url(../../assets/PC/forum/report_bac_mobile.png) center no-repeat;
                        background-size: 100%;
                        font-size: vw(24);
                        line-height: vw(87);
                        color: #fff;
                        text-align: center;
                      }
                    }
                  }
                }

                .reply_list {
                  padding: 0 0 0 vw(60);

                  .reply_item {
                    &>div:first-of-type {
                      font-size: vw(24);
                      margin-bottom: vw(12);

                      span {
                        font-size: vw(24);
                      }

                      div {
                        top: 1px;

                        img {
                          width: vw(170);
                          height: vw(50);
                        }

                        span {
                          font-size: vw(24);
                          left: vw(54);
                          top: vw(6);
                        }
                      }
                    }

                    &>div:nth-of-type(2) {
                      font-size: vw(24);
                      line-height: vw(36);
                    }

                    &>div:nth-of-type(3) {
                      a {
                        span {
                          font-size: vw(20);
                          margin-left: vw(8);
                        }

                        &:first-of-type {
                          svg {
                            width: vw(23);
                            height: vw(23);
                          }
                        }

                        &:nth-of-type(2) {
                          svg {
                            width: vw(22);
                            height: vw(20);
                          }
                        }

                        &:nth-of-type(3) {
                          position: relative;

                          span:first-of-type {
                            font-weight: bolder;
                          }

                          span:nth-of-type(2) {
                            position: absolute;
                            top: vw(30);
                            right: vw(-67);
                            width: vw(163);
                            height: vw(71);
                            background: url(../../assets/PC/forum/report_bac_mobile.png) center no-repeat;
                            background-size: 100%;
                            font-size: vw(24);
                            line-height: vw(87);
                            color: #fff;
                            text-align: center;
                          }
                        }
                      }
                    }

                    &:nth-of-type(n + 1) {
                      margin-top: vw(10);
                    }
                  }

                  .loadMore {
                    font-size: vw(24);
                  }
                }
              }

              .noData {
                font-size: vw(32);
              }

              .publish_area {
                margin: 0;
                position: fixed;
                bottom: 50px;
                left: 0;
                width: 100%;
                padding: vw(30) vw(40);
                background: rgba(0, 0, 0, 0.5);

                /deep/ .el-input {
                  width: vw(519);
                  height: vw(66) !important;
                  line-height: vw(66) !important;

                  input {
                    width: vw(519);
                    height: vw(66) !important;
                    line-height: vw(66) !important;
                    font-size: vw(24);
                  }
                }

                a {
                  width: vw(130);
                  height: vw(66);
                  font-size: vw(24);
                  line-height: vw(66);
                }
              }

              .discuss_pagination {
                display: none;
              }
            }

            .onBottomLoad_btn {
              display: block;
              width: vw(166);
              height: vw(52);
              color: #738294 !important;
              line-height: vw(52);
              background: #e6e6e6;
              font-size: vw(20);
              text-align: center;
              margin: 0 auto vw(70);
            }
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
$mobil_base: 750;

@function vw($true) {
  @return ($true / $mobil_base) * 100vw;
}

@media (max-width: 750px) {
  .description table {
    width: 100%;
  }
}
</style>
